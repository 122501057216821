<template>
  <div class="content">
    <headBar title="团课记录" left-arrow @click-left="back" ref="head" />
    <div class="tab-box" :style="{ top: height + 'px' }">
      <div class="tab">
        <span :class="{ act: courseMode == 'effcient' }" @click="changeMod('effcient')">有效团课</span>
        <span :class="{ act: courseMode == 'noeffcient' }" @click="changeMod('noeffcient')">无效团课</span>
      </div>
    </div>
    <div class="box">
      <p class="tips" v-if="courseMode == 'noeffcient'"><van-icon name="warning-o" class="mr5" />使用月卡免费权益的课程为无效团课</p>
      <div class="coruse-box">
        <div class="item" v-for="i in list">
          <p class="line1">
            <span>{{ i.courseName }}</span>
            <span>{{ i.courseEndTitle }}</span>
          </p>
          <div class="flex">
            <img class="pic" :src="i.courseMainimage" alt="">
            <div class="detail">
              <p>{{ i.teacherName }}</p>
              <p>{{ i.lessonEndStr }}</p>
              <p>{{ i.venueName }}</p>
              <p>{{ i.roomName }}</p>
            </div>
          </div>
        </div>
        <div class="empty" v-if="list.length == 0">
          <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/flag-202404/empty.png" alt="">
          <p class="mt10">暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headBar from '@/components/app/headBar'
import userMixin from '@/mixin/userMixin';
import wx from "weixin-js-sdk";
import { getParam } from '@/lib/utils'

export default {
  components: {
    headBar
  },
  mixins: [userMixin],
  data() {
    return {
      height: 0,
      beginTime: '',
      endTime: '',
      courseMode: 'effcient',
      list: []
    }
  },
  async created() {
    setTimeout(() => {
      this.height = this.$refs.head.$el.offsetHeight
    }, 100)
    await this.$getAllInfo(['userId'])
    const { beginTime, endTime } = getParam()
    this.beginTime = decodeURIComponent(beginTime)
    this.endTime = decodeURIComponent(endTime)
    this.getList()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeMod(mod) {
      this.courseMode = mod
      this.getList()
    },
    getList() {
      this.$axios.post(`${this.baseURLApp}/scheduleLesson/findEfficientLesson`, {
        beginTime: this.beginTime,
        endTime: this.endTime,
        courseMode: this.courseMode,
        userId: this.userId
      }).then((res) => {
        this.list = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}

.content {
  min-height: 100vh;
  background-color: #F5F5F5;
  padding-bottom: 30px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 30px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 30px);

  .tab-box {
    padding: 20px 32px;
    position: sticky;
    background-color: #F5F5F5;
    z-index: 100;

    .tab {
      width: 100%;
      height: 72px;
      background-color: #E9E9E9;
      border-radius: 70px;
      color: #3C454E;
      font-size: 24px;
      display: flex;
      align-items: center;
      padding: 4px;
      position: sticky;
      top: 0px;

      >span {
        flex: 1;
        text-align: center;

        &.act {
          background-color: #fff;
          height: 64px;
          line-height: 64px;
          border-radius: 60px;
        }
      }
    }
  }

  .box {
    padding: 20px 32px;

    .tips {
      font-size: 24px;
      color: #3C454E;
      margin-bottom: 23px;
    }

    .coruse-box {
      .item {
        background-color: #fff;
        width: 686px;
        border-radius: 16px;
        padding: 28px;
        padding-top: 37px;

        .line1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 34px;

          >span {
            &:first-child {
              font-size: 28px;
              color: #000000;
              font-weight: 600;
            }

            &:last-child {
              font-size: 26px;
              color: #9AA1A9;
            }
          }
        }

        .pic {
          width: 208px;
          height: 192px;
          border-radius: 8px;
          margin-right: 29px;
          object-fit: cover;
        }

        .detail {
          color: #000000;
          font-size: 26px;

          p+p {
            margin-top: 14px;
          }
        }
      }

      .item+.item {
        margin-top: 24px;
      }
    }
  }

  .empty {
    margin-top: 200px;
    font-size: 28px;
    color: #6C727A;
    text-align: center;

    img {
      width: 320px;
      height: 320px;
    }
  }

}</style>